export default {
  LOCALE: '中文（繁體香港）',
  NAV: {
    INDEX: '網誌',
    RSS: '訂閱',
    SEARCH: '搜尋',
    ABOUT: '關於',
    MAIL: '電郵',
    NAVIGATOR: '導航',
    ARCHIVE: '舊存檔'
  },
  COMMON: {
    ARTICLE_LIST: '文章清單',
    MORE: '更多',
    NO_MORE: '沒有更多了',
    LATEST_POSTS: '最新文章',
    TAGS: '標籤',
    NO_TAG: '無標籤',
    CATEGORY: '分類',
    SHARE: '分享',
    SCAN_QR_CODE: 'QRCode',
    URL_COPIED: '連結已複製！',
    TABLE_OF_CONTENTS: '目錄',
    RELATE_POSTS: '相關文章',
    COPYRIGHT: '版權',
    AUTHOR: '作者',
    URL: '連結',
    ANALYTICS: '分析',
    POSTS: '篇文章',
    ARTICLE: '文章',
    VISITORS: '位訪客',
    VIEWS: '次瀏覽',
    COPYRIGHT_NOTICE: '本文使用 CC BY-NC-SA 4.0 版權協議，如轉載請註明出處。',
    RESULT_OF_SEARCH: '篇搜尋结果',
    ARTICLE_DETAIL: '完整文章',
    PASSWORD_ERROR: '密碼錯誤！',
    ARTICLE_LOCK_TIPS: '文章已上鎖，請輸入訪問密碼',
    SUBMIT: '提交',
    POST_TIME: '發表於',
    LAST_EDITED_TIME: '最後更新',
    NEXT_POST: '下一篇',
    PREV_POST: '上一篇'
  },
  PAGINATION: {
    PREV: '上一頁',
    NEXT: '下一頁'
  },
  SEARCH: {
    ARTICLES: '搜尋文章',
    TAGS: '搜尋標籤'
  },
  POST: {
    BACK: '返回',
    TOP: '回到頁頂'
  },
  AI_SUMMARY: {
    NAME: 'AI 智能摘要',
  }
}
